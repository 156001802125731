export const dashboardStats = {
  title: "Key Performance Indicators",
  postTitle: "(Last 30 days)",
  summary:
    "These key indicators offer a comprehensive view of the immigration application tracking system's performance, ensuring that stakeholders can quickly assess its effectiveness, identify areas for improvement, and make data-driven decisions.",
  data: [
    {
      name: "Application Status",
      summary: "Number of applications submitted",
      stat: "289",
      statUnit: "",
      sparkline: {
        active: true,
        data: [30, 5, 10, 5, 20, 8, 15],
        limit: 5,
        width: 100,
        height: 20,
        margin: 5,
      },
      data: [
        { label: "New", value: 13 },
        { label: "Documentation In Progress", value: 2 },
        { label: "USCIS Pending", value: 10 },
        { label: "Suspended", value: 10 },
        { label: "Approved", value: 2 },
      ],
      // previousStat: "70,946",
      // change: "12%",
      // changeType: "increase",
    },
    {
      name: "Processing Time",
      summary: "Average processing time for applications",
      stat: "2.8",
      statUnit: "months",
      sparkline: {
        active: false,
      },
      data: [
        { label: "Average Process Time", value: 1.6, unit: "month" },
        { label: "Document Verification Time", value: 5, unit: "days" },
        { label: "Payment Processing Time", value: 1, unit: "month" },
      ],
      // previousStat: "56.14%",
      // change: "2.02%",
      changeType: "",
    },
    {
      name: "Cost Metrics",
      summary: "Budget adherence & Projections", //Cost per application processed
      //and expenditure breakdown
      stat: 24.57,
      statUnit: "%",
      previousStat: "",
      change: "",
      changeType: "",
      sparkline: {
        active: true,
        data: [3000, 1000, 15000, 0, 4000, 13999],
      },
      data: [
        { label: "Advance", value: 25000, unit: "USD" },
        { label: "1st Installment", value: 8200, unit: "USD" },
        { label: "2nd Installment", value: 2055, unit: "USD" },
        { label: "Projections in Q2-2024", value: "45K", unit: "USD" },
      ],
    },
    {
      name: "Efficiency Metrics", //Avg. Tresponse Rate
      summary: "Efficiency of workflow processes",
      stat: 58.16,
      statUnit: "%",
      // previousStat: "56.14%",
      // change: "2.02%",
      // changeType: "increase",
      sparkline: {
        active: false,
      },
      data: [
        // { label: "Processing Efficiency", value: 2.5 },
        { label: "Response Time", value: 2.8, unit: "months" },
        { label: "Success Rate", value: 69, unit: "%" },
      ],
    },
    {
      name: "Geographic Distribution",
      summary: "Demographic information on applicants",
      stat: "5",
      statUnit: "Countries",
      sparkline: {
        active: false,
      },
      data: [
        { label: "Dubai", value: 13 },
        { label: "Canada", value: 10 },
        { label: "USA", value: 10 },
      ],
    },
    {
      name: "Compliance Metrics",
      summary: "Adherence to immigration laws and policies",
      stat: "15",
      statUnit: "",
      sparkline: {
        active: false,
      },
      data: [
        { label: "Approved", value: 13 },
        { label: "Request for Evidence (RFE)", value: 1 },
        { label: "Rejected", value: 1 },
      ],
    },
    {
      name: "Users",
      summary: "Measure Users & Engagements",
      stat: "15",
      statUnit: "active members",
      sparkline: {
        active: true,
        data: [11, 12, 14, 16, 18, 20, 50, 100],
        mean: true,
      },
      data: [
        { label: "Agents", value: 5 },
        // { label: "Active Members", value: 90 },
        { label: "Total Members", value: 1790 },
      ],
    },
  ],
};

export const casesStats = {
  title: "Immigration Application",
  postTitle: "Statistics",
  summary:
    "The application list provides a comprehensive overview of all immigration applications currently under consideration. ",
  data: [
    {
      name: "Application Status",
      summary: "Number of applications submitted",
      stat: "289",
      // previousStat: "70,946",
      // change: "12%",
      // changeType: "increase",
    },
    {
      name: "Processing Time",
      summary: "Average processing time for applications",
      stat: "2.8",
      statUnit: "Months",

      // previousStat: "56.14%",
      // change: "2.02%",
      changeType: "",
    },
    {
      name: "Efficiency Metrics", //Avg. Tresponse Rate
      summary: "Efficiency of workflow processes",
      stat: "58.16%",
      // previousStat: "56.14%",
      // change: "2.02%",
      // changeType: "increase",
    },
    {
      name: "Geographic Distribution",
      summary: "Demographic information on applicants",
      stat: "5",
      statUnit: "Countries",
    },
  ],
};
