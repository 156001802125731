import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { UserType } from "../model/types";

export const userColumns = [
  {
    accessorKey: "name",
    header: "Name",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
    enableGrouping: false,
    // enableSorting: true,
    meta: {
      cellClassName: "",
    },
  },
  {
    accessorKey: "registerationDate",
    header: "Registeration Date",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
    enableGrouping: false,
    // enableSorting: true,
  },
  {
    accessorKey: "email",
    header: "Email",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
    enableGrouping: false,
    // enableSorting: true,
  },
  {
    accessorKey: "mobile",
    header: "Mobile",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
    enableGrouping: false,
    // enableSorting: true,
  },
  {
    accessorKey: "process",
    header: "Process",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
    enableGrouping: false,
    // enableSorting: true,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
    enableGrouping: false,
    // enableSorting: true,
    meta: {
      // cellClassName: "text-right",
    },
  },
  {
    accessorKey: "uuid",
    header: "",
    cell: ({ row }) => (
      <>
        {row.original.index && (
          <div className="flex">
            <a
              href="#"
              onClick={(e) => {
                console.log(e);
              }}
              className="text-blue-600 no-underline hover:text-blue-900 no-line-through"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                />
              </svg>
            </a>

            <a
              href="#"
              onClick={(e) => {
                console.log(e);
              }}
              className="ml-3 text-blue-600 no-underline hover:text-blue-900 no-line-through"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                />
              </svg>
            </a>

            {/* {row.original && (
              <a
                target="_blank"
                href={`/payments/invoice/${row.original.uuid}/${row.original.m}/${row.original.year}/${row.original.invoice_no}`}
                className="text-blue-600 no-underline hover:text-blue-900 no-line-through"
              >
                View{" "}
                <span className="sr-only">, {row.original.invoice_no}</span>
              </a>
            )} */}
          </div>
        )}
      </>
    ),
    // enableHiding: false,
    enableColumnFilter: false,
    enableGrouping: false,
    // columnVisibility: false,
    meta: {
      cellClassName: "text-right",
    },
  },
  // {
  //   accessorKey: "uuid",
  //   header: "",
  //   cell: ({ row }) => (
  //     <a
  //       href={`/employees/${row.original.uuid}`}
  //       className="text-blue-600 no-underline hover:text-blue-900 no-line-through"
  //     >
  //       Profile<span className="sr-only">, {row.original.name}</span>
  //     </a>
  //   ),
  //   enableColumnFilter: false,
  //   enableGrouping: false,
  //   // enableSorting: true,
  // },
];
