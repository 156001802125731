import React from "react";

export default function Brand() {
  return (
    <>
      {/* <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      //   dataSlot="icon"
      className="w-6 h-6 text-gray-900"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5-3.9 19.5m-2.1-19.5-3.9 19.5"
      />
    </svg> */}
      <img
        className="inline-block w-10 h-10 rounded-full"
        src={"/assets/images/logo.png"}
        alt=""
      />{" "}
      {/* Community 360 */}
    </>
  );
}
