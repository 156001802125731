import { ColumnDef } from "@tanstack/react-table";
import {
  casesBreadcrumLinks,
  homeBreadcrumLinks,
  plr,
  sampleCommands,
  usersBreadcrumLinks,
} from "../common/constants";
import { UserType } from "../model/types";
import React from "react";
import TanstackTable from "../components/TanstackTable";
import PageHeader from "../blocks/page-header";
import Breadcrums from "../components/breadcrums";
import jsonData from "../demo/cases.json";
import { userColumns } from "../common/user-columns";
import Stats from "../blocks/stats";
import { casesStats, dashboardStats } from "../common/stats";
import { casesColumns } from "../common/cases-columns";

export default function Cases() {
  const columns = React.useMemo<ColumnDef<UserType, any>[]>(
    () => casesColumns,
    []
  );

  return (
    <>
      <section className={plr}>
        <Breadcrums
          breadcrumLinks={casesBreadcrumLinks}
          module={"Application Case"}
          commands={sampleCommands}
          commandLabel="Actions"
          groups={[""]}
        />
        <PageHeader title="Application Cases"></PageHeader>

        <Stats stats={casesStats} />

        <TanstackTable columns={columns} data={jsonData}></TanstackTable>
      </section>

      {/* <TableBlock people={people} /> */}
    </>
  );
}
