import { Dialog, Transition } from "@headlessui/react";
import {
  BookmarkAltIcon,
  BriefcaseIcon,
  CalendarIcon,
  DocumentIcon,
  FilterIcon,
  FireIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UserIcon,
  XIcon,
} from "@heroicons/react/outline";
import { BellIcon, MapIcon, UsersIcon } from "@heroicons/react/outline";
import React, { Fragment, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import Brand from "../components/brand";
import Me from "../components/me";
import classNames from "classnames";

// import Animate from "animate.css-react";
import { Animated } from "react-animated-css";
import Breadcrums from "../components/breadcrums";
import {
  bgClr1,
  clr1,
  sampleCommands,
  usersBreadcrumLinks,
} from "../common/constants";

const user = {
  name: "Tim Cook",
  email: "Tim.Cook@community360.app",
  imageUrl:
    // "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    // "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    // "https://media.idownloadblog.com/wp-content/uploads/2018/07/Tim-Cook-memoji.jpg",
    "/assets/images/customer-service.png",
};

const navigation = [
  { name: "Home", href: "/home", icon: HomeIcon },
  { name: "Users", href: "/users", icon: UsersIcon },
  { name: "Users", href: "/case", icon: InboxIcon },
  { name: "Calendar", href: "/calendar", icon: CalendarIcon },
  // { name: "Map", href: "/map", icon: MapIcon },
  { name: "Filters", href: "/filters", icon: FilterIcon },
];

const navigation2 = [
  // { name: "Notifications", href: "/notifications", icon: BellIcon },
];

const Layout = ({ props, children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const location = useLocation();

  return (
    <>
      <div className="flex h-full FadeAdmin">
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex lg:hidden"
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex flex-col flex-1 w-full h-full max-w-xs bg-white focus:outline-none">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-4 -mr-12">
                    <button
                      type="button"
                      className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="pt-5 pb-4">
                  <div className="flex items-center flex-shrink-0 px-4">
                    {/* <img
                      className="w-auto h-8"
                      src="https://tailwindui.com/img/logos/workflow-mark.svg?color=gray&shade=600"
                      alt="Workflow"
                    /> */}
                    <Brand />
                  </div>
                  <nav aria-label="Sidebar" className="mt-5">
                    <div className="px-2 space-y-1">
                      {navigation.map((item) => (
                        <Link
                          to={item.href}
                          preventScrollReset={true}
                          state={{ some: "value" }}
                          key={item.name}
                          className={classNames(
                            "flex items-center p-3 rounded-lg",
                            location.pathname.startsWith(item.href)
                              ? "text-blue-500"
                              : "text-gray-500"
                          )}
                          // href={item.href}
                          // hover:bg-gray-700
                          // className={({ isActive, isPending }) => {
                          //   "",
                          //     isActive ? "text-blue-500" : "text-gray-500";
                          // }}
                        >
                          <item.icon
                            className={classNames(
                              "w-6 h-6 mr-4 text-gray-400 group-hover:text-gray-500",
                              location.pathname.startsWith(item.href)
                                ? "text-blue-500"
                                : "text-gray-500"
                            )}
                            aria-hidden="true"
                          />{" "}
                          {item.name}
                          <span className="sr-only">{item.name}</span>
                        </Link>
                        // <a
                        //   key={item.name}
                        //   href={item.href}
                        //   className="flex items-center p-2 text-base font-medium text-gray-600 rounded-md group hover:bg-gray-50 hover:text-gray-900"
                        // >
                        //   <item.icon
                        //     className="w-6 h-6 mr-4 text-gray-400 group-hover:text-gray-500"
                        //     aria-hidden="true"
                        //   />
                        //   {item.name}
                        // </a>
                      ))}
                    </div>
                  </nav>
                </div>
                <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
                  {/* <Me /> */}
                  <nav aria-label="Sidebar" className="mt-5">
                    <div className="px-2 space-y-1">
                      {navigation2.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="flex items-center p-2 text-base font-medium text-gray-600 rounded-md group hover:bg-gray-50 hover:text-gray-900"
                        >
                          <item.icon
                            className="w-6 h-6 mr-4 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </nav>

                  <a
                    href="#"
                    className="flex-shrink-0 block group"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <div className="flex items-center">
                      <div className="block">
                        <img
                          className="inline-block w-10 h-10 rounded-full-x"
                          src={user.imageUrl}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                          {user.name}
                        </p>
                        <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                          Account Settings
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {/* max-h-full hover:max-h-screen */}
        <div className="hidden lg:flex lg:flex-shrink-0 ">
          <div className="flex flex-col w-20 h-full shadow-2xl sideMenu">
            <div
              className={classNames(
                "flex flex-col flex-1 min-h-0 overflow-y-auto bg-blue-50 shadow-md",
                bgClr1,
                clr1
              )}
            >
              <div className="flex-1">
                <div className="flex items-center justify-center py-4">
                  {/* <img
                    className="w-auto h-8"
                    src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                    alt="Workflow"
                  /> */}{" "}
                  <Brand />
                </div>
                <nav
                  aria-label="Sidebar"
                  className="flex flex-col items-center py-0 space-y-0"
                >
                  {navigation.map((item) => (
                    <Link
                      to={item.href}
                      preventScrollReset={true}
                      state={{ some: "value" }}
                      key={item.name}
                      className={classNames(
                        "flex items-center p-3 rounded-lg",
                        location.pathname.startsWith(item.href)
                          ? "text-blue-500"
                          : "text-gray-500"
                      )}
                      data-tooltip-target={item.name}
                    >
                      <item.icon className="w-6 h-6" aria-hidden="true" />
                      <span className="sr-only">{item.name}</span>
                      <div
                        id={item.name}
                        role="tooltip"
                        className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                      >
                        {item.name}
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </Link>
                  ))}
                </nav>
              </div>
              <div className="flex flex-shrink-0 pb-5 userMenu">
                {/* <Me /> */}
                <nav
                  aria-label="Sidebar"
                  className="flex flex-col items-center py-0 space-y-0"
                >
                  {navigation2.map((item) => (
                    <Link
                      to={item.href}
                      preventScrollReset={true}
                      state={{ some: "value" }}
                      key={item.name}
                      className={classNames(
                        "flex items-center p-3 rounded-lg",
                        location.pathname.startsWith(item.href)
                          ? "text-blue-500"
                          : "text-gray-500"
                      )}
                    >
                      <item.icon className="w-6 h-6" aria-hidden="true" />
                      <span className="sr-only">{item.name}</span>
                    </Link>
                  ))}
                </nav>

                <a href="#" className="flex-shrink-0 w-full">
                  <img
                    className="block w-10 h-10 mx-auto rounded-full-x"
                    src={user.imageUrl}
                    alt=""
                  />
                  <div className="sr-only">
                    <p>{user.name}</p>
                    <p>Account settings</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-1 min-w-0 overflow-hidden">
          {/* Mobile top navigation */}
          <div className="lg:hidden">
            <div
              className={classNames(
                "flex items-center justify-between px-4 py-2 bg-blue-50 sm:px-6 lg:px-8",
                bgClr1,
                clr1
              )}
            >
              <div>
                <Brand />
              </div>
              <div>
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-12 h-12 -mr-3 text-white rounded-md focus:outline-none focus:ring-0 focus:ring-inset focus:ring-white"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <MenuIcon
                    className="w-6 h-6 text-gray-500 hover:text-gray-700"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>

          <Animated
            animationIn="zoomOutLeft"
            animationOut="fadeOut"
            isVisible={true}
          >
            <main className="flex flex-1 overflow-hidden">
              <section
                aria-labelledby="primary-heading"
                className="flex flex-col flex-1 h-full min-w-0 overflow-y-auto lg:order-last"
              >
                <Outlet />
              </section>

              {/* <aside className="hidden lg:block lg:flex-shrink-0 lg:order-first">
              <div className="relative flex flex-col h-full overflow-y-auto bg-white border-r border-gray-200 w-96">
              </div>
            </aside> */}
            </main>
          </Animated>
        </div>
      </div>
    </>
  );
};

export default Layout;
