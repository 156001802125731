import React from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../blocks/page-header";
import { plr } from "../common/constants";

export default function Indicator() {
  const params = useParams();
  console.log(params);

  return (
    <main>
      <section className={plr}>
        <PageHeader title={"Indicator"} summary="Summary"></PageHeader>
      </section>
    </main>
  );
}
