/* This example requires Tailwind CSS v2.0+ */
import { DotsVerticalIcon, HomeIcon, SearchIcon } from "@heroicons/react/solid";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import Command from "./command";
import { useEffect, useState } from "react";
import { sectionClass } from "../common/constants";

interface BreadcrumProps {
  breadcrumLinks?: any;
  commands?: any;
  module?: any;
  groups?: string[];
  commandLabel?: any;
  id?: any;
}

const defaultProps: BreadcrumProps = {
  breadcrumLinks: [],
};

export default function Breadcrums(props: BreadcrumProps) {
  const [openComand, setOpenCommand] = useState(false);

  const [group, setGroup] = useState("");

  console.log(props);

  const [links, setLinks] = useState<any>([]);

  useEffect(() => {
    console.log(props.id);
    if (props.id) {
      setLinks([
        ...props.breadcrumLinks,
        {
          name: `Case-${props.id}`,
          href: `/case/${props.id}`,
          current: true,
        },
      ]);
    } else {
      setLinks([...props.breadcrumLinks]);
    }
  }, []);

  return (
    <section className={classNames(sectionClass)}>
      <nav className="flex max-w-full" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-0">
          <li>
            <div>
              <a href="/home" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="flex-shrink-0 w-5 h-5 text-blue-600-x hover:text-blue-900-x"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>

          {links &&
            links.map((page: any) => (
              <li key={page.name}>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <a
                    href={page.href}
                    className={classNames(
                      "ml-1 text-sm font-medium text-gray-500 hover:text-gray-700",
                      page.current == true
                        ? "text-blue-900 hover:text-blue-900"
                        : "text-blue-600 hover:text-blue-900"
                    )}
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}

          {props.commands && props.commands.length > 0 && (
            <li className="">
              <div className="flex pl-2">
                {(props.groups && props.groups.length == 0) ||
                  (props.groups && props.groups[0] == "" && (
                    <a
                      href="#"
                      className="flex text-gray-400 hover:text-gray-500"
                      onClick={() => {
                        setGroup("");
                        setOpenCommand(true);
                      }}
                    >
                      {/* <svg className="flex-shrink-0 w-5 h-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                                    </svg> */}
                      {/* <ExternalLinkIcon className="flex-shrink-0 w-4 h-3 text-gray-300-x hover:text-graye-700" aria-hidden="true" /> */}

                      <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 flex">
                        {props.commandLabel || props.module}
                      </span>

                      <span className="sr-only">Home</span>
                    </a>
                  ))}

                {props.groups?.includes("Actions") && (
                  <a
                    href="#"
                    className="flex text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      setGroup("Actions");
                      setOpenCommand(true);
                    }}
                  >
                    <span className="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">
                      Actions
                    </span>
                  </a>
                )}

                {props.groups?.includes("Statement") && (
                  <a
                    href="#"
                    className="flex text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      setGroup("Statement");
                      setOpenCommand(true);
                    }}
                  >
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 flex">
                      Statement
                    </span>
                  </a>
                )}

                {props.groups?.includes("Report") && (
                  <a
                    href="#"
                    className="flex text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      setGroup("Report");
                      setOpenCommand(true);
                    }}
                  >
                    <span className="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">
                      Reports
                    </span>
                  </a>
                )}

                {props.groups?.includes("Forecast") && (
                  <a
                    href="#"
                    className="flex text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      setGroup("Forecast");
                      setOpenCommand(true);
                    }}
                  >
                    <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                      Forecast
                    </span>
                  </a>
                )}
              </div>
            </li>
          )}
        </ol>
      </nav>

      {props.commands.length > 0 && (
        <Command
          group={group}
          open={openComand}
          setOpen={setOpenCommand}
          items={props.commands}
        ></Command>
      )}
    </section>
  );
}
