import React from "react";
import PageHeader from "../blocks/page-header";
import { homeBreadcrumLinks, plr, sampleCommands } from "../common/constants";
import Toolbar from "../blocks/toolbar";
import Stats from "../blocks/stats";
import Breadcrums from "../components/breadcrums";
import { InformationCircleIcon } from "@heroicons/react/solid";
import Banner from "../components/banner";
import { dashboardStats } from "../common/stats";

export default function Home() {
  return (
    <>
      <main>
        <section className={plr}>
          <Breadcrums
            breadcrumLinks={homeBreadcrumLinks}
            module={"Home"}
            commands={sampleCommands}
            commandLabel="Actions"
            groups={[""]}
          />

          {/* Banner */}
          {/* <Banner
          summary={"Big news! We're excited to announce a brand new product."}
        /> */}

          <PageHeader
            title={"Aim High Advisory - Dashboard Summary"}
            summary="A concise overview of key information and metrics presented in a single line."
          >
            {/* Toolbar Sample */}
            <Toolbar />
          </PageHeader>

          {/* Dashboard Stats */}
          <Stats stats={dashboardStats} />
        </section>
      </main>
      <section className={plr}>
        {/* <div className="p-4 rounded-md bg-blue-50">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="w-5 h-5 text-blue-900"
                aria-hidden="true"
              />
            </div>
            <div className="flex-1 ml-3 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                A new software update is available. See what’s new in version
                2.0.4.
              </p>
              <p className="mt-3 text-sm md:mt-0 md:ml-6">
                <a
                  href="#"
                  className="font-medium text-blue-700 whitespace-nowrap hover:text-blue-600"
                >
                  Details <span aria-hidden="true">&rarr;</span>
                </a>
              </p>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
}
