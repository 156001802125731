import { Children } from "react";
import { plr } from "../common/constants";

interface PageHeaderProps {
  title: string;
  summary?: string;

  children?: any;
}

const defaultProps: PageHeaderProps = {
  title: "",
};

export default function PageHeader(props: PageHeaderProps) {
  return (
    <section>
      {/* <header className="bg-white shadow-x">
        <div className="max-w-full mx-auto py-6-x px-4-x sm:px-6-x lg:px-8-x">
          <h1 className="text-3xl font-bold text-gray-900">{props.title}</h1>
        </div>
      </header> */}

      <section className="py-2 border border-t-0 border-b-0 border-l-0 border-r-0 border-gray-300">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold text-gray-900">
              {props?.title}
            </h1>
            <p className="mt-0 text-sm text-gray-700">{props?.summary}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {props.children}
          </div>
        </div>
      </section>

      {/* // <div className="md:flex md:items-center md:justify-between">
      //   <div className="flex-1 min-w-0">
      //     <h2 className="text-2xl font-bold leading-7 sm:text-3xl sm:truncate">
      //       {props.title}
      //     </h2>
      //   </div>
      //   <div className="flex mt-4 md:mt-0 md:ml-4">
      //     <button />
      //       type="button"
      //       className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
      //     >
      //       Edit
      //     </button>
      //     <button />
      //       type="button"
      //       className="inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
      //     >
      //       Publish
      //     </button>
      //   </div>
    // </div> */}
    </section>
  );
}
