import React from "react";
import PageHeader from "../blocks/page-header";
import { plr } from "../common/constants";

export default function Notifications() {
  return (
    <section className={plr}>
      <PageHeader title={"Notifications"}>
        {/* <button
          type="button"
          className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
        >
          Refresh
        </button> */}
      </PageHeader>
    </section>
  );
}
