import React from "react";

import { MapContainer, TileLayer } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

export default function Map() {
  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []);
  // const [markers, setMarkers] = React.useState([]);
  // let niveau = filterGare1;

  return (
    <section className="pi-[80px] pr-[0px]">
      <MapContainer zoom={5} center={[45, 20]}>
        <TileLayer
          // url="https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=F4ZxF5g8ioWE3GlTx3i0#-0.2/0.00000/76.51878"
          // attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
          url={`https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en&gl=US&${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&basemap=satellite&map_action=map&layer=transit`}
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />

        {/* <ReactLeafletGoogleLayer
          apiKey="AIzaSyBKmnLGdbglBWjnsiBJn12laWmLFD0j0rE"
          type={"roadmap"}
        /> */}

        {/* <GetZoom /> */}
      </MapContainer>
    </section>

    // <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
    //   <TileLayer
    //     attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    //     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    //   />
    //   <Marker position={[51.505, -0.09]}>
    //     <Popup>
    //       A pretty CSS3 popup. <br /> Easily customizable.
    //     </Popup>
    //   </Marker>
    // </MapContainer>
  );
}
