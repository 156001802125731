export const plr = "pi-[100px] pr-[20px] pt-1 px-4 sm:px-6-0 lg:px-8-0";

export const bgClr1 = "bg-blue-50";
export const clr1 = "bg-gray-50";

export const homeBreadcrumLinks = [
  { name: "Home", href: "/home", current: true },
];

export const usersBreadcrumLinks = [
  { name: "Users", href: "/users", current: true },
];

export const filtersBreadcrumLinks = [
  { name: "Filters", href: "/filters", current: true },
];

export const calendarBreadcrumLinks = [
  { name: "Calendar", href: "/calendar", current: true },
];

export const casesBreadcrumLinks = [
  { name: "Dashboard", href: "/dashboard", current: false },
  { name: "Cases", href: "/case", current: true },
];

export const caseBreadcrumLinks = [
  { name: "Dashboard", href: "/dashboard", current: false },
  { name: "Cases", href: "/case", current: false },
  { name: "Case", href: "/case", current: true },
];

export const sectionClass = "px-0 pt-5 py-0 mx-auto sm:px-6 lg:px-0 pt-2 pb-3";

export const sampleCommands = [
  // {
  //   id: 11,
  //   name: "Payment Invoices",
  //   category: "Actions",
  //   url: "/payments/invoice",
  // },
];
