import React from "react";
import { useLocation, useParams } from "react-router-dom";
import PageHeader from "../blocks/page-header";
import {
  calendarBreadcrumLinks,
  plr,
  sampleCommands,
  usersBreadcrumLinks,
} from "../common/constants";
import Breadcrums from "../components/breadcrums";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import moment from "moment";

export default function Calendar() {
  const params = useParams();
  console.log(params);

  let { state } = useLocation();
  console.log(state);

  const events = [{ title: "Meeting", start: new Date() }];

  // a custom render function
  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  return (
    <main>
      <section className={plr}>
        <Breadcrums
          breadcrumLinks={calendarBreadcrumLinks}
          module={"Calendar"}
          commands={sampleCommands}
          commandLabel="Actions"
          groups={[""]}
        />
        <PageHeader title={"Calendar"}></PageHeader>

        <FullCalendar
          initialView="dayGridMonth"
          events={events}
          showNonCurrentDates={false}
          contentHeight={500}
          dayMaxEventRows={true}
          eventContent={renderEventContent}
          dayMaxEvents={true}
          plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
          headerToolbar={{
            start: "today prev next",
            center: "title",
            end: "dayGridMonth dayGridWeek listWeek",
          }}
          hiddenDays={[]}
          weekends={true}
          // eventClick={props.onDateClick}
          displayEventTime={false}
          validRange={{ end: moment().endOf("month").toDate() }}
        />
      </section>
    </main>
  );
}
