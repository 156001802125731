import { ColumnDef } from "@tanstack/react-table";
import {
  homeBreadcrumLinks,
  plr,
  sampleCommands,
  usersBreadcrumLinks,
} from "../common/constants";
import { UserType } from "../model/types";
import React from "react";
import TanstackTable from "../components/TanstackTable";
import PageHeader from "../blocks/page-header";
import Breadcrums from "../components/breadcrums";
import jsonData from "../demo/data.json";
import { userColumns } from "../common/user-columns";
import Stats from "../blocks/stats";
import { dashboardStats } from "../common/stats";

export default function Users() {
  const columns = React.useMemo<ColumnDef<UserType, any>[]>(
    () => userColumns,
    []
  );

  return (
    <>
      <section className={plr}>
        <Breadcrums
          breadcrumLinks={usersBreadcrumLinks}
          module={"Users"}
          commands={sampleCommands}
          commandLabel="Actions"
          groups={[""]}
        />
        <PageHeader title="Users">
          {/* <div className="inline-flex rounded-md shadow-sm" role="group">
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            >
              Profile
            </button>
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            >
              Settings
            </button>
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            >
              Messages
            </button>
          </div> */}
          {/* <Toolbar /> */}
        </PageHeader>

        <TanstackTable columns={columns} data={jsonData}></TanstackTable>
      </section>

      {/* <TableBlock people={people} /> */}
    </>
  );
}
