import React, { Fragment, useState } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/solid";
import {
  MenuAlt1Icon,
  MinusSmIcon,
  PlusSmIcon,
  XIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import {
  filtersBreadcrumLinks,
  plr,
  sampleCommands,
  usersBreadcrumLinks,
} from "../common/constants";
import Breadcrums from "../components/breadcrums";
import PageHeader from "../blocks/page-header";
import { ColumnDef } from "@tanstack/react-table";
import { userColumns } from "../common/user-columns";
import { UserType } from "../model/types";
import TanstackTable from "../components/TanstackTable";
import jsonData from "../demo/data.json";

export default function Filters() {
  const sortOptions = [
    { name: "Most Popular", href: "#", current: true },
    { name: "Best Rating", href: "#", current: false },
    { name: "Newest", href: "#", current: false },
    { name: "Price: Low to High", href: "#", current: false },
    { name: "Price: High to Low", href: "#", current: false },
  ];
  const subCategories = [
    { name: "Canada", href: "#" },
    { name: "Dubai", href: "#" },
    { name: "Unites States", href: "#" },
  ];
  const filters = [
    {
      id: "color",
      name: "Color",
      options: [
        { value: "white", label: "White", checked: false },
        { value: "beige", label: "Beige", checked: false },
        { value: "blue", label: "Blue", checked: true },
        { value: "brown", label: "Brown", checked: false },
        { value: "green", label: "Green", checked: false },
        { value: "purple", label: "Purple", checked: false },
      ],
    },
    {
      id: "category",
      name: "Category",
      options: [
        { value: "new-arrivals", label: "New Arrivals", checked: false },
        { value: "sale", label: "Sale", checked: false },
        { value: "travel", label: "Travel", checked: true },
        { value: "organization", label: "Organization", checked: false },
        { value: "accessories", label: "Accessories", checked: false },
      ],
    },
    {
      id: "size",
      name: "Size",
      options: [
        { value: "2l", label: "2L", checked: false },
        { value: "6l", label: "6L", checked: false },
        { value: "12l", label: "12L", checked: false },
        { value: "18l", label: "18L", checked: false },
        { value: "20l", label: "20L", checked: false },
        { value: "40l", label: "40L", checked: true },
      ],
    },
  ];

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const columns = React.useMemo<ColumnDef<UserType, any>[]>(
    () => userColumns,
    []
  );

  const [state, setState] = useState({
    filterSection: true,
    isFiltersVisible: false,
  });

  const toggleFilters = () => {
    setState((prevState) => ({
      ...state,
      isFiltersVisible: !prevState.isFiltersVisible,
    }));
  };

  return (
    <>
      <section className={plr}>
        <Breadcrums
          breadcrumLinks={filtersBreadcrumLinks}
          module={"Filters"}
          commands={sampleCommands}
          commandLabel="Actions"
          groups={[""]}
        />

        <button>Show Box</button>

        <PageHeader title="Filters"></PageHeader>
        {/* Background color split screen for large screens */}
        {/* <div
        className="fixed top-0 left-0 w-1/2 h-full "
        aria-hidden="true"
      /> */}
        {/* <div
        className="fixed top-0 right-0 w-1/2 h-full bg-gray-50"
        aria-hidden="true"
      /> */}

        <div className="inline-flex py-2 rounded-md shadow-none">
          <a
            href="#"
            onClick={toggleFilters}
            aria-current="page"
            className="px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
          >
            Filters
          </a>
          {/* <a
            href="#"
            className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
          >
            Reset
          </a> */}
          <a
            href="#"
            className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
          >
            Reset
          </a>
        </div>

        <div className="relative flex flex-col min-h-screen">
          {/* 3 column wrapper */}
          <div className="flex-grow w-full max-w-full mx-auto xl:px-8-x lg:flex">
            {/* Left sidebar & main wrapper */}
            <div className="flex-1 min-w-0 xl:flex">
              <div
                className={classNames(
                  state.isFiltersVisible ? "lg:block" : "hidden",
                  "border-b border-gray-200 xl:border-b-0 xl:flex-shrink-0 xl:w-64 xl:border-r-x xl:border-gray-200"
                )}
              >
                <div className="h-full py-6 pl-4 pr-6 sm:pl-6 lg:pl-8-x xl:pl-0">
                  {/* Start left column area */}
                  <div
                    className="relative h-full"
                    style={{ minHeight: "12rem" }}
                  >
                    {/* <div className="absolute inset-0 border-2 border-gray-200 border-dashed rounded-lg" /> */}
                    {/* Filters */}
                    <form className="hidden lg:block">
                      {filters.map((section) => (
                        <Disclosure
                          as="div"
                          key={section.id}
                          className="py-6 border-b border-gray-200"
                        >
                          {({ open }) => (
                            <>
                              <h3 className="flow-root -my-3">
                                <Disclosure.Button className="flex items-center justify-between w-full py-3 text-sm text-gray-400 hover:text-gray-500">
                                  <span className="font-medium text-gray-900">
                                    {section.name}
                                  </span>
                                  <span className="flex items-center ml-6">
                                    {open ? (
                                      <MinusSmIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <PlusSmIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel className="pt-6">
                                <div className="space-y-4">
                                  {section.options.map((option, optionIdx) => (
                                    <div
                                      key={option.value}
                                      className="flex items-center"
                                    >
                                      <input
                                        id={`filter-${section.id}-${optionIdx}`}
                                        name={`${section.id}[]`}
                                        defaultValue={option.value}
                                        type="checkbox"
                                        defaultChecked={option.checked}
                                        className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                      />
                                      <label
                                        htmlFor={`filter-${section.id}-${optionIdx}`}
                                        className="ml-3 text-sm text-gray-600"
                                      >
                                        {option.label}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}

                      <h3 className="my-2 font-semibold sr-only-x">Country</h3>
                      <ul
                        role="list"
                        className="pb-6 space-y-4 text-sm font-medium text-gray-900 border-b border-gray-200"
                      >
                        {subCategories.map((category) => (
                          <li key={category.name}>
                            <a href={category.href}>{category.name}</a>
                          </li>
                        ))}
                      </ul>
                    </form>
                  </div>
                  {/* End left column area */}
                </div>
              </div>

              {/* h-[calc(100vh-274px)]  overflow-hidden */}
              <div className=" lg:min-w-0 lg:flex-1">
                <div className="h-full px-1 py-2 sm:px-6 lg:px-2">
                  {/* Start main area*/}
                  <div
                    className="relative h-full"
                    // style={{ minHeight: "16rem" }}
                  >
                    {/* <div className="absolute inset-0 border-2 border-gray-200 border-dashed rounded-lg" /> */}
                    <TanstackTable
                      columns={columns}
                      data={jsonData}
                    ></TanstackTable>
                  </div>
                  {/* End main area */}
                </div>
              </div>
            </div>

            {/* <div className="pr-4 bg-gray-50 sm:pr-6 lg:pr-8 lg:flex-shrink-0 lg:border-l lg:border-gray-200 xl:pr-0">
            <div className="h-full py-6 pl-6 lg:w-80">
              {/ * Start right column area * /}
              <div className="relative h-full" style={{ minHeight: "16rem" }}>
                <div className="absolute inset-0 border-2 border-gray-200 border-dashed rounded-lg" />
              </div>
              {/ * End right column area * /}
            </div>
          </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
