// import logo from "./logo.svg";
import {
  BrowserRouter,
  Route,
  Routes,
  Redirect,
  Switch,
  Navigate,
} from "react-router-dom";
// import "./App.css";

import Layout from "./layout/layout";
import Notifications from "./pages/notifications";
import Users from "./pages/users";
import NotFound from "./pages/not-found";
import Home from "./pages/home";
import Blank from "./pages/blank";
import Map from "./pages/map";
import { useEffect, useState } from "react";
import Calendar from "./pages/calendar";
import Indicator from "./pages/indicator";
import Filters from "./pages/filters";
import Cases from "./pages/cases";
import Case from "./pages/case";

function App() {
  // useEffect(() => {
  //   const script = document.createElement("script");

  //   script.src = "https://maps.googleapis.com/maps/api/js";
  //   script.async = true;
  //   script.defer = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  const [loggedIn] = useState(true);

  return (
    <>
      <Routes>
        {/* <Route exact path="/">
          {loggedIn ? <Navigate to="/home" /> : <Blank />}
        </Route> */}

        <Route path="/" element={<Navigate replace to="/home" />} />

        {/* <Redirect from="/" to="/home" /> */}
        {/* <Route path="/" element={<Blank />} /> */}
        <Route element={<Layout brandBreadcrum={true} />}>
          <Route path="/home" element={<Home />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="/users" element={<Users />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/map" element={<Map />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/filters" element={<Filters />} />

          <Route path="/case" element={<Cases />} />
          <Route path="/case/:cid" element={<Case />} />

          <Route path="/indicator/:id" element={<Indicator />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>

      {/* <Layout>
        <div className="">
          
        </div>
      </Layout> */}
    </>
  );
}

export default App;
