/* This example requires Tailwind CSS v2.0+ */
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import {
  Sparklines,
  SparklinesLine,
  SparklinesBars,
  SparklinesReferenceLine,
  SparklinesSpots,
  SparklinesCurve,
  SparklinesNormalBand,
} from "react-sparklines";

import { nanoid } from "nanoid";

export default function Stats(props: { stats }) {
  return (
    <div className="pt-4">
      {props.stats.title && (
        <h3 className="text-lg font-semibold leading-6 text-gray-900">
          {props.stats.title}{" "}
          <small className="font-normal">{props.stats.postTitle}</small>
        </h3>
      )}

      {props.stats.summary && (
        <p className="text-sm text-gray-500">{props.stats.summary}</p>
      )}

      <dl className="grid grid-cols-2 mt-3 overflow-hidden divide-y divide-gray-200 rounded-lg shadow-x shadow-md-x bg-gray-50-x md:grid-cols-4 md:divide-y-0 md:divide-x">
        {props.stats.data.map((item) => (
          <>
            <div key={item.name} className="px-4 py-5 bg-white sm:p-6">
              <dt className="text-sm font-semibold text-gray-900">
                {item.name}
              </dt>
              <small className="font-normal text-gray-600">
                {item.summary}
              </small>

              <p className="py-2">
                {item.sparkline?.active && (
                  <Sparklines
                    data={item.sparkline?.data || []}
                    // imit={5}
                    // width={100}
                    // height={20}
                    // margin={5}
                  >
                    {/* <SparklinesBars /> */}
                    <SparklinesReferenceLine type="mean" />
                    {/* <SparklinesLine color="blue" /> */}
                    {/* <SparklinesLine style={{ fill: "none" }} /> */}
                    <SparklinesSpots />
                    <SparklinesCurve />
                    {/* <SparklinesNormalBand /> */}
                    {/* <SparklinesBars
                      style={{
                        stroke: "white",
                        fill: "#41c3f9",
                        fillOpacity: ".25",
                      }}
                    />
                    <SparklinesLine
                      style={{ stroke: "#41c3f9", fill: "none" }}
                    /> */}
                    {/* <text ref="tooltip" x="0" y="0" visibility="hidden">
                      Tooltip
                    </text> */}
                    {/* <SparklinesLine /> */}
                  </Sparklines>
                )}
              </p>

              <dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
                <div className="flex items-baseline text-3xl font-semibold text-blue-600">
                  {item.stat}

                  {item.statUnit && (
                    <small className="text-sm">&nbsp; {item.statUnit}</small>
                  )}

                  {item.previousStat && (
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {item.previousStat}
                    </span>
                  )}
                </div>

                {item.change && (
                  <>
                    <div
                      className={classNames(
                        item.changeType === "increase"
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800",
                        "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                      )}
                    >
                      {item.changeType && item.changeType === "increase" ? (
                        <ArrowSmUpIcon
                          className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                      ) : (
                        item.changeType && (
                          <ArrowSmDownIcon
                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        )
                      )}

                      {item.change && (
                        <>
                          <span className="sr-only">
                            {item.changeType === "increase"
                              ? "Increased"
                              : "Decreased"}{" "}
                            by
                          </span>
                          {item.change}
                        </>
                      )}
                    </div>
                  </>
                )}
              </dd>
              {item.data && (
                <ul className="flex flex-col max-w-xs pt-2 divide-y divide-gray-200 dark:divide-gray-700">
                  {item.data.map((dataElement) => (
                    <li className="inline-flex items-center px-0 py-1 -mt-px text-sm font-medium text-gray-800 gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-slate-900 dark:border-gray-700 dark:text-white">
                      <div className="flex justify-between w-full">
                        {dataElement.label}
                        <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-700 rounded-full bg-blue-500-x">
                          {dataElement.value}{" "}
                          <small className="pl-1">{dataElement.unit}</small>
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </>
        ))}
      </dl>
    </div>
  );
}
